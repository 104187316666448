<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
  >
    <v-card>
      <v-card-text>
        <!-- title imput -->
        <v-text-field
          label="tilte"
          v-model="item.title"
        />
        <!-- url imput -->
        <v-text-field
          label="url"
          v-model="item.url"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn 
          text
          @click="close"
        >
          cancel
        </v-btn>

        <v-btn 
          text
          @click="save"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
/*    id: {
      type: Number
    }*/
  },
  data () {
    return {
      dialog: false,
      index: 0,
      item: {},
/*      title: "",
      url: "",
      isList: false,*/
    }
  },
  methods: {
    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(i){
      /** open dialog */
      this.index = i
      this.item.url = this.items[i].url
      this.item.title = this.items[i].title
      this.dialog = true
    },
    open2(title, url){
      this.title = title
      this.url = url
      this.dialog = true
    },

    /**
     * Handler of left hand side FAB
     */
    close(){
      this.dialog = false
    },

    save(){
      this.$emit('save', this.index, this.item)
      this.close()
    },
/*
      if (localStorage.homeList) {
        localStorage.homeList = localStorage.homeList  + "\n" + this.url +"|" + this.title
      } else {
        localStorage.homeList = this.url +"|" + this.title
      }
      this.dialog = false
      console.log("this.$parent",this.$parent)
      this.$parent.$parent.refresh()
    }
*/
  }
}
</script>