
<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
  >
    <v-card>
      <v-card-title class="grey--text"> QR Code</v-card-title>
      <v-card-text>
        <img :src="qrurl" />
        <pre class="black--text">&lt;img src="{{qrurl}}"/&gt;</pre>
      </v-card-text>
      <v-card-actions>
        <v-btn 
          text
          @click="close"
        >
          close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {url: {
    type: String,
    default: ""
  }},
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    open(){
      this.dialog = true
    },
    close(){
      this.dialog = false
    },
  },
  computed: {
    linkurl(){
      return 'https://encyclolist.uedasoft.com/list/' + encodeURIComponent(this.url)
    },
    msg(){
      return (encodeURIComponent(this.linkurl))
    },
    qrurl(){
      return 'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=' + this.msg
    }
  }
}
</script>