import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import List from '@/views/List.vue'
import MakeDirectLink from '@/views/MakeDirectLink.vue'

import Document from './document.route.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/list/:url',
    name: 'List',
    component: List
  },
  {
    path: '/makedirectlink',
    name: 'MakeDirectLink',
    component: MakeDirectLink
  },
  {
    path: '/addItem',
    name: 'AddItem',
    component: Home,
    props: { newsletterPopup: false }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  ...Document.routes,

  // Not Found is transit to HOME
  {
    path: '*',
    name: 'Home',
    component: Home
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
