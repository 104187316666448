<template>
  <div class="home">
    <AddDialog
      ref="addDialog"
      @added="itemAdded"
    />
    <DirectLinkDialog
      :url="url"
      ref="directLinkDialog"
    />
    <v-card
      class="mx-auto"
      max-width="500"
      scrollable
    >
      <v-card-title>
        {{title}}
      </v-card-title>

      <v-card-text>
        <ShowList
          :items="enclst.items"
          :currentURL="url"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn 
          text
          @click="directLink"
        >
          Direct Link
        </v-btn>
        <v-spacer/>
        <v-btn 
          text
          @click="add"
        >
          add to home
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
//import list from '@/components/list'
import ShowList from "@/components/ShowList.vue"
import AddDialog from '@/dialogs/Add.vue'
//import EditDialog from '@/dialogs/Edit.vue'
import DirectLinkDialog from '@/dialogs/DirectLink/Body.vue'
import directLinkMixin from '@/dialogs/DirectLink/mixin'
import { EncLst} from 'enclst-core-js'

export default {
  components: {AddDialog, /*EditDialog, */ShowList, DirectLinkDialog},
  mixins: [/*list, */directLinkMixin],

  data: () => ({
    //
    url: "",
    title: "",
//    items: [],
    enclst: new EncLst(),
  }),
  methods: {
    add(){
//      const item = new Item(this.url + "|" + this.title)
//      this.$refs.addDialog.openWithAddItem(item)
      this.$refs.addDialog.openWithAddItem(this.url, this.title)
  },
    cancelEdit(){
//      this.refresh()
    },
    itemAdded(item){
      const data = localStorage.homeList
      let enclst = new EncLst(data)
      enclst.items.push(item)
      localStorage.homeList = enclst.serialize()

    }

  },
  async mounted(){
//    const url = "https://raw.githubusercontent.com/UedaTakeyuki/EncListInJapanese/main/fern"
    this.url = this.$route.params.url
    const res = await fetch(this.url) 
    if (res.status == 200) {
      const data = await res.text();
      this.enclst = new EncLst(data)
      console.log("enclst", this.enclst)
      this.title = this.enclst.title
    }
  },
  // https://abillyz.com/watanabe/studies/226
  watch: {
    $route () {
      location.reload()
    }
  },
}
</script>
