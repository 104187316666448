<template>
  <v-card
    class="mx-auto"
    max-width="500"
  >
<!--    <AddDialog
      ref="addDialog"
    />-->
    <EditDialog
      :items="enclst.items"
      @save="save"
      @cancel="cancelEdit"
      ref="editDialog"
    />
    <ExportDialog
      :items="enclst.items"
      ref="exportDialog"
    />
    <v-card-title>home</v-card-title>

    <v-card-text>
      <ShowList
        :items="enclst.items"
      />
    </v-card-text>

    <v-card-actions>
      <v-btn 
        text
        @click="exportList"
      >
        export
      </v-btn>
      <v-spacer/>
      <v-btn
        text
        @click="edit"
      >
        edit
      </v-btn>
    </v-card-actions>
  </v-card>  
</template>

<script>
//import list from '@/components/list'
import ShowList from "@/components/ShowList.vue"
//import AddDialog from '@/dialogs/Add.vue'
import EditDialog from '@/dialogs/Edit.vue'
import ExportDialog from '@/dialogs/Export.vue'
import { EncLst } from 'enclst-core-js'

export default {
  components:{/*AddDialog, */EditDialog, ShowList, ExportDialog},
//  mixins: [list],
  data: () => ({
    //
/*    title: "",
    items: [],*/
    enclst: new EncLst(),
  }),

  methods: {
    edit(){
      this.$refs.editDialog.open()
    },
    refresh(){
      const data = localStorage.homeList
      this.enclst = new EncLst(data)
/*
      let resArray = this.stringToResArray(data)
      this.title = this.getTitle(resArray)
      this.items = this.getListItems(resArray)
*/
    },
    exportList(){
      this.$refs.exportDialog.open()
    },
    save(items){
      console.log("items",items)
      localStorage.homeList = this.enclst.serialize()
      /*
      let data = items.map(x => x.url + " | " + x.title).join('\n')
      console.log("data",data)
      localStorage.homeList = data
*/
      this.refresh()
    },
    cancelEdit(){
      this.refresh()
    }
  },
  mounted(){
    console.log("window", window)
    this.refresh()
  }
}
</script>