<template>
  <v-card
    class="mx-auto"
    max-width="500"
    scrollable
  >
    <v-card-title>Make Direct Link</v-card-title>
    <DirectLinkDialog
      :url="url"
      ref="directLinkDialog"
    />
    <v-card-text>
      <v-text-field
        label="url"
        v-model="url"
      />
    </v-card-text>
    <v-card-actions>
        <v-btn 
          text
          @click="directLink"
        >
          Direct Link
        </v-btn>
      </v-card-actions>

  </v-card>
</template>

<script>
import DirectLinkDialog from '@/dialogs/DirectLink/Body.vue'
import directLinkMixin from '@/dialogs/DirectLink/mixin'

export default {
  components: {DirectLinkDialog},
  mixins: [directLinkMixin],

  data () {
    return {
      url: "",
    }
  },
/*  methods: {
    directLink(){
      this.$refs.directLinkDialog.open(this.url)
    }
  },*/
}
</script>