<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
  >
    <v-card>
      <v-toolbar
        color="#f5f5f5"
      >
        <v-toolbar-title class="grey--text mx-auto">Export</v-toolbar-title>
      </v-toolbar>
      <v-card-title>Home Links</v-card-title>
      <v-card-text>
          {{ homeLinks }}
      </v-card-text>
      <v-card-actions>
        <v-btn
        text
        @click="close"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(){
      /** open dialog */
      this.dialog = true
    },
    /**
     * Handler of left hand side FAB
     */
    close(){
      this.dialog = false
    },
  },
  computed: {
    homeLinks(){
      console.log("items", this.items)
      let data = this.items.map(x => x.value.first() + " | " + x.title).join('\n')
      console.log("data", data)
      return data
    }
  }
}
</script>