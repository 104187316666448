<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    eager
    class="mx-auto"
    max-width="500"
  >
    <v-card>
      <EditItemDialog
        :items="items"
        @save="saveItem"
        ref="editItemDialog"
      />
      <AddItemDialog
        @added="itemAdded"
        ref="addItemDialog"
      />
      <v-toolbar
        color="#f5f5f5"
      >
        <v-toolbar-title class="grey--text mx-auto">Edit</v-toolbar-title>
      </v-toolbar>
      <v-card-title>Home Links</v-card-title>
      <v-card-text>
        <v-list>
          <draggable v-model="items" draggable=".item">
          <v-list-item
            v-for="(item,i) in items"
            :key="i"
            @click="selected(i)"
            class="item"
          >
            <v-list-item-content
            >
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon
                @click.stop="deleteItem(i)"
              >
                <v-icon color="grey lighten-1">mdi-delete-forever</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          </draggable>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn
        text
        @click="cancel"
        >
          Cancel
        </v-btn>

        <v-spacer/>

        <v-btn
        text
        @click="openAddItemDialog"
        >
          Add
        </v-btn>
        
        <v-spacer/>

        <v-btn
        text
        @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import list from '@/components/list'
import draggable from 'vuedraggable'
import EditItemDialog from '@/dialogs/EditItem.vue'
import AddItemDialog from '@/dialogs/Add.vue'

export default {
  components: {
    draggable,
    EditItemDialog,
    AddItemDialog
  },
  mixins: [list],
  props: {
    items: {
      type: Array,
      default: () => []
    },
  },
  data: () => ({
    //
    dialog: false,
  }),
  methods: {
    /**
     * Open this dialog
     * @param object item An item of the list.
     */
    open(){
      /** open dialog */
      this.dialog = true
    },
    openWithAddItem(url, title){
      this.open()
      setTimeout(() => {
        this.openAddItemDialogAddItem(url, title)
//        this.$refs.addItemDialog.focus();
      }, 200)
    },
    /**
     * Handler of left hand side FAB
     */
    close(){
      this.dialog = false
    },
    deleteItem(i){
      if (confirm("Do you really want to delete this?")){
        this.items.splice(i,1)
      }
    },
    save(){
      console.log("this.items",this.items)
      this.$emit('save', this.items)
      this.close()
    },
    cancel(){
      this.$emit('cancel')
      this.close()
    },

    //
    // for EditItemDialog
    //
    selected(i){
      // open EditItemDialog
      this.$refs.editItemDialog.open(i)
    },
    saveItem(index, item){
      console.log("index",index)
      console.log("item",item)
      this.items[index].url = item.url
      this.items[index].title = item.title
    },

    //
    // for AddItemDialog
    //
    openAddItemDialog(){
      this.$refs.addItemDialog.open()
    },
    openAddItemDialogAddItem(url, title){
      this.$refs.addItemDialog.openWithAddItem(url, title)
      this.$refs.addItemDialog.focus();
    },
    itemAdded(item){
      this.items.push(item)
    }
  }
}
</script>