<template>
  <v-list>
    <ListItem
      v-for="(item,i) in items"
      :key="i"
      :index="i"
      :title="item.title"
      @selected="onSelected"
    />
  </v-list>
</template>

<script>
import ListItem from "@/components/ListItem.vue"
import { EncLst } from "enclst-core-js"
export default {
  components:{ListItem},
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currentURL: {
      type: String,
      default: ""
    }
  },
  methods: {
    onSelected(index){
      console.log("index",index)
      console.log("this.items",this.items)
      let url = this.items[index].value.first()
      console.log("url",url)
      // get file extension of the url
      let extension = url.split('.').pop()
      console.log("extension",extension)
      console.log("extension",this.string2bytes(extension))
      console.log("extension == enclst",extension == "enclst")
      if (extension == "enclst"){
        console.log("push")
        if (this.currentURL == ""){
          this.$router.push({ name: 'List', params: { url: url } })
        } else {
//          console.log("currentURL",this.currentURL)
          const nextURL = EncLst.makeURLfromCurrentURLandPath(this.currentURL, url)
//          console.log("nextURL",nextURL)
          this.$router.push({ name: 'List', params: { url: nextURL } })
        }
      } else {
        console.log("open")
        window.location.href = url
        //window.open(url, '_blank')
      }

    },
    // https://gist.github.com/seekseep/e5c289791265dafee786640942ee2e4d
    // string -> byte[]
    string2bytes(string) {
      var bytes = string.split('').map(char => char.charCodeAt(0))
      return bytes;
    },
  },
}
</script>

